@import './utils.scss';
@import './mixins.scss';

@media (screen and min-width: 1264px) {
  h1 {
    font-size: 5rem;
  }

  h2 {
    font-size: 4rem;
  }

  h3 {
    font-size: 3rem;
  }

  h4 {
    font-size: 2rem;
  }

  section {
    padding: 0 20vw 100px;
    &#landing {
      padding: 25vh 20vw;
    }
  }

  .button.cta {
    width: 50%;
  }

  #success-message {
    padding: 2vh 20vw 0;
  }
}
