@import './utils.scss';
@import './mixins';

@media (screen and min-width: 600px) {
  :root {
    --navbar-height: 64px;
  }

  h1 {
    font-size: 4rem;
  }

  h2 {
    font-size: 3rem;
  }

  h3 {
    font-size: 2rem;
  }

  h4 {
    font-size: 1.25rem;
    padding: 0.125rem 0;
  }

  header {
    nav {
      #logo {
        font-size: 2rem;
        font-weight: bold;
      }
    }
  }

  section {
    padding: 0 4rem 100px;
    &#landing {
      padding: 25vh 25vw;
      .content {
        #subtitle {
          text-align: center;
        }
      }
    }
  }
  #success-message {
    padding: 2vh 4rem 0;
  }
}
