@media (screen and min-width: 960px) {
  h1 {
    font-size: 4.5rem;
  }

  h2 {
    font-size: 3.5rem;
  }

  h3 {
    font-size: 2.5rem;
  }

  h4 {
    font-size: 1.5rem;
  }

  footer {
    font-size: 1rem;
  }

  section {
    padding: 0 10vw 100px;
  }

  header {
    nav {
      div {
        #hamburger {
          display: none;
        }
        #links {
          display: flex;
          list-style: none;
          li {
            a.navigation-link {
              text-decoration: none;
              color: var(--text-color);
              padding: 0 0.5rem;
            }
          }
        }
      }
    }
  }

  #contact {
    padding: 0 5vw 100px;
    #extra-contact-info {
      flex-direction: row;
      align-items: start;
      justify-content: space-between;
      #contact-form {
        width: 35%;
      }
      #map {
        width: 60%;
      }
    }
  }
  #success-message {
    padding: 2vh 10vw 0;
  }
}
