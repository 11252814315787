@import url('https://fonts.googleapis.com/css2?family=Montserrat&display=swap');
@import './utils';
@import './mixins';

:root {
  --primary: steelblue;
  --text-color: #eee;
  --navbar-height: 48px;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  &::before,
  &::after {
    box-sizing: border-box;
  }
}

h1 {
  font-size: 3.25rem;
}

h2 {
  font-size: 2.25rem;
}

h3 {
  font-size: 1.5rem;
}

h4 {
  font-size: 1rem;
}

footer {
  font-size: 0.75rem;
}

.button {
  border: none;
  background-color: var(--primary);
  padding: 0.75rem 1.5rem;
  border-radius: 0.5rem;
  font-size: 1.5rem;
  color: var(--text-color);
  margin-top: 1rem;
  text-decoration: none;
  text-align: center;

  &:hover {
    filter: brightness(105%);
    cursor: pointer;
    transform: scale(1.05);
    box-shadow: 8px 8px 8px rgba(0, 0, 0, 0.25);
  }

  &:active {
    filter: brightness(95%);
    cursor: pointer;
    transform: scale(1);
  }

  &.outlined {
    border: 1px solid var(--primary);
    background-color: var(--text-color);
    color: var(--primary);
  }

  &.block,
  &.cta {
    width: 100%;
  }
}

html {
  scroll-behavior: smooth;
}

body {
  position: relative;
  height: 100%;
  background-color: #222;
  font-family: 'Montserrat', sans-serif;
  color: #fff;
}

header {
  height: var(--navbar-height);
  position: fixed;
  top: 0;
  width: 100%;
  background-color: var(--primary);
  z-index: 3;
  color: var(--text-color);
  box-shadow: 8px 8px 8px rgba(0, 0, 0, 0.25);
  display: flex;
  justify-content: space-between;
  align-items: center;
  #logo {
    font-size: 1.5rem;
    font-weight: bold;
  }
  padding: 0 1rem;
  nav {
    div {
      height: 100%;
      position: relative;

      #hamburger {
        cursor: pointer;
      }

      #links {
        display: none;

        &.show {
          list-style: none;
          display: flex;
          flex-direction: column;
          align-items: center;
          position: absolute;
          left: -13rem;
          width: 15rem;
          padding: 0.75rem;
          border-radius: 0.5rem;
          box-shadow: 8px 8px 8px rgba(0, 0, 0, 0.25);
          background-color: var(--text-color);
          li {
            display: block;
            width: 100%;
            & > a.navigation-link {
              padding: 0.5rem 0;
              text-decoration: none;
              text-align: center;
              color: var(--primary);
              display: block;
              width: inherit;
            }
          }
        }
      }
    }
  }
}

main {
  margin-top: var(--navbar-height);

  &::before {
    @include background-styling();
    background-image: url(/assets/img/marcela-laskoski-YrtFlrLo2DQ-unsplash.jpeg);
  }
}

section {
  min-height: calc(100vh - var(--navbar-height));
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  padding: 0 1rem 4rem;

  //&#landing::before {
  //  @include background-styling();
  //  background-image: url(/assets/img/marcela-laskoski-YrtFlrLo2DQ-unsplash.jpeg);
  //}
  //
  //&#about::before {
  //  @include background-styling();
  //  background-image: url(/assets/img/ruan-richard-Hzu0y00TNUc-unsplash.jpeg);
  //}
  //
  //&#availability::before {
  //  @include background-styling();
  //  background-image: url(/assets/img/jorik-kleen-iKuT7niw8vE-unsplash.jpeg);
  //}
  //
  //&#testimonials::before {
  //  @include background-styling();
  //  background-image: url(/assets/img/zac-bromell-v7_0NTL_RwI-unsplash.jpeg);
  //}
  //
  //&#contact::before {
  //  @include background-styling();
  //  background-image: url(/assets/img/zac-bromell-IJRMI1BGPbw-unsplash.jpeg);
  //}
}

.icon-down {
  position: absolute;
  bottom: 15px;
  left: 50%;
  transform: translateX(-50%);
  cursor: pointer;

  &::before {
    content: url('/assets/icons/chevron-down-circle.svg');
  }
}

.anchor {
  scroll-margin-top: var(--navbar-height);
}

.section-title {
  display: flex;
  justify-content: center;
  padding: 0.5rem 0 3rem;
}

.sub-section-title {
  display: flex;
  justify-content: center;
  padding: 1rem 0;
}

#landing {
  padding: 150px 1rem;
  .content {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-weight: bold;
  }
}

#about {
  .content {
    @include grid-cards();
    img {
      justify-self: end;
    }
  }
}

#availability {
  .shows {
    @include grid-cards();
    grid-gap: 1em;
    .show {
      padding: 1rem;
      background-color: var(--primary);
      margin: 0.25rem 0;
      width: 100%;
      border-radius: 0.5rem;
      box-shadow: 8px 8px 8px rgba(0, 0, 0, 0.25);
      display: flex;
      justify-content: space-between;
      text-decoration: none;
      color: var(--text-color);
    }

    .hover {
      &:hover {
        filter: brightness(105%);
        cursor: pointer;
        transform: scale(1.05);
        box-shadow: 8px 8px 8px rgba(0, 0, 0, 0.25);
      }

      &:active {
        filter: brightness(95%);
        cursor: pointer;
        transform: scale(1);
      }
    }
  }
}

#testimonials {
  .content {
    @include grid-cards();

    .testimonial {
      position: relative;
      background-color: var(--primary);
      @include card();
      p {
        margin-top: 0.5rem;
        padding-left: 1.5rem;
        text-align: center;
        &::before {
          content: '-';
          padding-right: 3px;
        }
      }

      &::before {
        content: '';
        position: absolute;
        top: -50px;
        left: -10px;
        display: block;
        width: 96px;
        height: 96px;
        background-size: 96px 96px;
        background-image: url('/assets/icons/format-quote-open.svg');
        background-repeat: no-repeat;
      }
    }
  }
}

#contact {
  #contact-links {
    padding-bottom: 1rem;
    @include center-column();
    .contact-info {
      a {
        display: flex;
        align-items: center;
        text-decoration: none;
        color: var(--text-color);
        span {
          margin-right: 10px;
        }
      }
    }
  }
  #extra-contact-info {
    display: flex;
    flex-direction: column;
    align-items: center;
    #contact-form,
    #map {
      width: 100%;
      margin: 1rem 0;
    }
    #contact-form {
      background-color: var(--primary);
      @include card();
      .form-group {
        padding: 0.5rem 0;
        input,
        textarea {
          width: 100%;
          display: block;
          padding: 0.5rem;
          border-radius: 1rem;
          border: none;
          &:focus {
            outline: none;
            border: 2px solid #000;
            border-radius: 1rem;
          }
        }
        small.error {
          display: none;
          font-style: italic;
          &.error-show {
            padding-top: 5px;
            display: flex;
            align-items: center;
            span {
              padding-left: 5px;
            }
          }
        }
      }
    }
  }
  #map {
    height: 400px;
    border-radius: 1rem;
  }
}

#email-icon {
  &::before {
    content: url('/assets/icons/email_black_24dp.svg');
  }
}

#facebook-icon {
  &::before {
    content: url('/assets/icons/facebook_black_24dp.svg');
  }
}

#contact-button[disabled] {
  cursor: not-allowed;
  background-color: #333;
  color: var(--text-color);
  &:hover {
    transform: unset;
    filter: unset;
    box-shadow: unset;
  }
}

#success-message {
  padding: 2vh 1rem 0;
  display: none;
  justify-content: space-between;
  align-items: center;
  h3 {
    padding-right: 1rem;
  }
  button {
    background: none;
    border: none;
    outline: none;
    height: 24px;
    width: 24px;
    border-radius: 50%;
    cursor: pointer;
  }
}

footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: var(--navbar-height);
  background-color: rgba(0, 0, 0, 0.25);
  color: var(--text-color);
  opacity: 0.5;
  display: flex;
  justify-content: center;
  align-items: center;
  a {
    text-decoration: none;
    color: var(--text-color);
  }
}

@import './tablet';
@import './laptop';
@import './desktop';
