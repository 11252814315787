@mixin background-styling {
  content: "";
  background-size: cover;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0.25;
  background-attachment: fixed;
  z-index: -1;
}

@mixin center-flex {
  display: flex;
  justify-content: center;
  align-items: center;
}

@mixin center-column {
  display: flex;
  flex-direction: column;
  align-items: center;
}

@mixin card {
  padding: 1.5rem;
  border-radius: 1rem;
  box-shadow: 8px 8px 8px rgba(0, 0, 0, 0.25);
}

@mixin grid-cards {
  display: grid;
  grid-gap: 2em;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
}
